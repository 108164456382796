class CollapsePermissions {
  constructor() {
    this.jQuery = window.$
    this.bindEvents()
  }

  bindEvents() {
    this.addEventCollapseHide(this)
    this.addEventCollapseHideAll(this)
    this.updateCollectionCheckBoxes(this)
  }

  addEventCollapseHide(self) {
    self.jQuery('.collapse_permissions').click(function eventCollapseHide(event) {
      const userId = this.getAttribute('data-user-id')
      const userModuleEntityId = this.getAttribute('data-user-module-entity-id')
      let collapse = null

      if (userId) {
        collapse = self.jQuery(`#collapse_account_user_permissions_${userId}`)
        self.jQuery('[id^="collapse_user_"].show').collapse('hide')
      } else {
        collapse = self.jQuery(`#collapse_user_module_entity_permissions_${userModuleEntityId}`)
      }

      if (collapse.hasClass('show')) {
        event.preventDefault()
        event.stopPropagation()
        collapse.collapse('hide')
      }

      self.removeFormPermissions()
    })
  }

  addEventCollapseHideAll(self) {
    self.jQuery('.card-title').click(() => {
      self.jQuery('[id^="collapse_account_user_permissions_"].show').collapse('hide')
      self.jQuery('[id^="collapse_user_module_entity_permissions_"].show').collapse('hide')
    })
  }

  updateCollectionCheckBoxes(self) {
    self.jQuery('#authorization_resource_access_form_admin_access').change(function changeCheckBoxe() {
      const checkAdmValue = this.type === 'hidden' ? (this.value === 'true') : this.checked

      self.jQuery('.resource_actions_permissions').each(function changeCheckBox() {
        if (checkAdmValue) {
          this.checked = true
          this.disabled = true
        } else {
          this.disabled = false
        }
      })
    })
  }

  removeFormPermissions() {
    this.jQuery('[id^="collapse_account_user_permissions_"] .card-body').html('')
    this.jQuery('[id^="collapse_user_module_entity_permissions_"] .card-body').html('')
  }
}

export default CollapsePermissions
