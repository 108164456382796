import PhoneMask from '../../components/phone_mask'

class EditForm {
  constructor() {
    this.inputs = ['phone-mask']
    this.tabToogleElements = document.querySelectorAll('.nav-tabs .nav-link')
    this.applyMasks()
    this.bindEvents()
  }

  bindEvents() {
    this.tabToogleElements.forEach((tabToogleElement) => {
      tabToogleElement.addEventListener('click', (e) => {
        e.preventDefault()
        global.$(tabToogleElement).tab('show')
      })
    })
  }

  applyMasks() {
    new PhoneMask(this.inputs).execute()
  }
}

export default EditForm
