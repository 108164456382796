function enableClickableRow() {
  const clickableRows = document.querySelectorAll('.clickable-row')

  if (clickableRows) {
    clickableRows.forEach((element) => {
      element.addEventListener('mouseup', (event) => {
        const href = event.target.closest('tr').getAttribute('data-href')

        if (event.button === 1 || (event.ctrlKey && event.button === 0)) {
          window.open(href, '_blank')
          return
        }

        if (event.button === 0) { window.location = href }
      })

      element.querySelector('a').addEventListener('click', (event) => {
        event.stopPropagation()
      })
    })
  }
}

export default enableClickableRow
