import ModuleEntitiesStep from '../../components/module_entities_step'

class AddModules extends ModuleEntitiesStep {
  constructor() {
    super()
    this.jQuery = window.$
    this.bindEvents()
  }

  bindEvents() {
    const self = this

    self.jQuery('.invite_to_modules_btn').click(function inviteToModulesForm() {
      const userEmail = this.getAttribute('data-user-email')

      self.jQuery('#invite_to_modules_form_email').val(userEmail)
      self.headerMessage('invite_to_modules', userEmail)
      self.checkBoxDisable('invite_to_modules', userEmail)
    })
  }
}

export default AddModules
