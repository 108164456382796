import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import FlashMessage from '../../components/flash_message'
import ToggleSubmitButtonWithSpinner from '../../components/toggle_submit_button_with_spinner'

const errorMap = [
  { code: 'auth/user-not-found', message: 'Email não cadastrado.' },
  { code: 'auth/invalid-email', message: 'Email inválido.' },
  {
    code: 'auth/too-many-requests',
    message: 'Conta bloqueado devido ao alto número de tentativas de acesso, tente novamente mais tarde.',
  },
]

class ResetPasswordSendEmailForm {
  constructor() {
    this.form = document.getElementById('kt_login_forgot_form')
    this.emailInput = document.getElementById('forgot_password_email')
    this.submitButton = '#forgot-password-btn'
    this.flashMessage = new FlashMessage('.signin-signup-container')

    this.bindEvents()
  }

  bindEvents() {
    this.form.onsubmit = (event) => {
      event.preventDefault()
      this.sendResetPasswordEmailForm()
    }
  }

  sendResetPasswordEmailForm() {
    const auth = getAuth()
    const email = this.emailInput.value

    ToggleSubmitButtonWithSpinner.execute(this.submitButton)
    sendPasswordResetEmail(auth, email)
      .then(() => this.handleResetPasswordEmailResponse())
      .catch((error) => this.handleResetPasswordEmailError(error))
  }

  handleResetPasswordEmailResponse() {
    this.form.submit()
  }

  handleResetPasswordEmailError(error) {
    this.flashMessage.execute(this.errorMessageFromCode(error.code), 'danger')
    ToggleSubmitButtonWithSpinner.execute(this.submitButton)
  }

  errorMessageFromCode(errorCode) {
    const errorMessageMap = errorMap.filter((err) => err.code === errorCode)[0]

    if (errorCode === 'auth/user-not-found') {
      this.handleResetPasswordEmailResponse()
    }

    if (errorMessageMap) {
      return errorMessageMap.message
    }

    return 'Ocorreu um erro ao enviar o email de redefinição de senha.'
  }
}

export default ResetPasswordSendEmailForm
