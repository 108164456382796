import { getAuth, signInWithEmailAndPassword, updatePassword } from 'firebase/auth'
import FlashMessage from '../../components/flash_message'
import ToggleSubmitButtonWithSpinner from '../../components/toggle_submit_button_with_spinner'

class SecurityForm {
  constructor() {
    this.securityFormElement = document.querySelector('form#security_info_form')
    this.submitButtonSelector = 'form#security_info_form button[type="submit"]'
    this.emailInput = this.securityFormElement.querySelector('#password_change_email')
    this.currentPasswordInput = this.securityFormElement.querySelector('#password_change_current_password')
    this.newPasswordInput = this.securityFormElement.querySelector('#password_change_new_password')
    this.newPasswordConfirmationInput = this.securityFormElement
      .querySelector('#password_change_new_password_confirmation')

    this.flashMessage = new FlashMessage('form.password_change')

    this.bindEvents()
  }

  bindEvents() {
    const auth = getAuth()

    this.securityFormElement.addEventListener('submit', (e) => {
      e.preventDefault()
      ToggleSubmitButtonWithSpinner.execute(this.submitButtonSelector)

      const currentPassword = this.currentPasswordInput.value
      const email = this.emailInput.value

      if (this.checkPasswordConfirmation()) {
        signInWithEmailAndPassword(auth, email, currentPassword)
          .then((response) => { this.changePassword(response.user) })
          .catch((error) => { this.handleSignInError(error.code) })
      }
    })
  }

  checkPasswordConfirmation() {
    const newPassword = this.newPasswordInput.value
    const newPasswordConfirmation = this.newPasswordConfirmationInput.value

    if (newPassword === newPasswordConfirmation) { return true }

    this.flashMessage.execute('Nova senha e Confirmação nova senha devem possuir o mesmo valor.', 'danger')
    this.clearFormInputs()
    ToggleSubmitButtonWithSpinner.execute(this.submitButtonSelector)

    return false
  }

  changePassword(user) {
    const newPassword = this.newPasswordInput.value

    updatePassword(user, newPassword)
      .then(() => { this.handleChangePasswordResponse() })
      .catch((error) => this.handleChangePasswordError(error.code))
  }

  handleChangePasswordResponse() {
    this.flashMessage.execute('Senha atualizada com sucesso.', 'success')
    this.clearFormInputs()
    ToggleSubmitButtonWithSpinner.execute(this.submitButtonSelector)
  }

  handleChangePasswordError(errorCode) {
    this.flashMessage.execute(errorCode, 'danger')
    this.clearFormInputs()
    ToggleSubmitButtonWithSpinner.execute(this.submitButtonSelector)
  }

  handleSignInError(errorCode) {
    let errorMessage = 'Ocorreu um erro ao autenticar, por favor tente novamente.'

    if (errorCode === 'auth/wrong-password') {
      errorMessage = 'Senha atual inválida.'
    }

    this.flashMessage.execute(errorMessage, 'danger')
    this.clearFormInputs()
    ToggleSubmitButtonWithSpinner.execute(this.submitButtonSelector)
  }

  clearFormInputs() {
    this.currentPasswordInput.value = ''
    this.newPasswordInput.value = ''
    this.newPasswordConfirmationInput.value = ''
  }
}

export default SecurityForm
