import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.GOOGLE_CLOUD_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
}

const firebase = initializeApp(firebaseConfig)

export default firebase
