class AccountShow {
  constructor() {
    this.tabs = document.getElementById('accountTab')
    this.togglesActivateModule = document.querySelectorAll('.submit_activate_module')
    this.jQuery = window.$
    this.bindEvents()
  }

  bindEvents() {
    document.addEventListener('turbolinks:load', () => { this.changeCurrentTab() })
    this.togglesActivateModule.forEach((toggle) => { this.addOnChangeConfirm(toggle) })
  }

  changeCurrentTab() {
    const { currentTab } = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    if (currentTab) {
      this.jQuery(`#accountTab button[data-target="#${currentTab}"]`).tab('show')
    }
  }

  addOnChangeConfirm(element) {
    element.addEventListener('change', () => {
      const message = 'Tem certeza que deseja ativar/desativar esse módulo?'

      // eslint-disable-next-line no-alert
      window.confirm(message) ? element.form.submit() : this.preventChange(element)
    })
  }

  preventChange(element) {
    const checkbox = element

    checkbox.checked = !checkbox.checked
  }
}

export default AccountShow
