import ModuleEntitiesStep from '../../components/module_entities_step'

class Invite extends ModuleEntitiesStep {
  constructor() {
    super()
    this.wizardEl = document.querySelector('.wizard')
    this.submitBtn = this.wizardEl.querySelector('[data-wizard-type="action-submit"]')
    this.jQuery = window.$
    this.accept_terms = document.querySelector('#accept_terms')
    this.terms_div = document.querySelector('#terms_div')

    this.wizard = new window.KTWizard(this.wizardEl, {
      startStep: 1,
      clickableSteps: false,
    })

    this.validateForm()
    this.bindWizardEvents()
    this.bindEvents()
  }

  bindEvents() {
    this.check_adm = document.querySelector('#invites_form_permission_adm')
    this.can_invite = document.querySelector('#invites_form_can_invite')
    this.can_delete = document.querySelector('#invites_form_can_delete')
    this.can_give_permission = document.querySelector('#invites_form_can_give_permission')
    this.can_access_invoice = document.querySelector('#invites_form_can_access_invoice')

    this.check_adm.addEventListener('change', () => {
      this.checkItens(this.check_adm.checked)
      this.disableItens(this.check_adm.checked)
      this.checkModules(this.check_adm.checked)
    })
  }

  checkItens(boolean) {
    this.can_invite.checked = boolean
    this.can_delete.checked = boolean
    this.can_give_permission.checked = boolean
    this.can_access_invoice.checked = boolean
  }

  disableItens(boolean) {
    this.can_invite.disabled = boolean
    this.can_delete.disabled = boolean
    this.can_give_permission.disabled = boolean
    this.can_access_invoice.disabled = boolean
  }

  bindWizardEvents() {
    this.bindSubmit()
    this.bindStepChanged()
  }

  select_terms() {
    this.accept_terms.checked = !this.accept_terms.checked
    this.submitBtn.disabled = !this.accept_terms.checked
  }

  bindSubmit() {
    const self = this

    this.terms_div.addEventListener('click', () => {
      this.select_terms()
    })

    this.accept_terms.addEventListener('click', () => {
      this.select_terms()
    })

    this.submitBtn.addEventListener('click', () => {
      if (this.accept_terms.checked) {
        self.jQuery('.invites_form').trigger('submit')
      }
    })

    this.jQuery('.invites_form').on('keypress', (e) => {
      if (e.keyCode === 13) { return false }

      return true
    })
  }

  bindStepChanged() {
    this.wizard.on('change', () => { this.blockInvalidForm() })
    this.wizard.on('changed', () => { this.displayWizardStep(this.wizard.getStep()) })
  }

  blockInvalidForm() {
    if (this.wizard.getStep() === 1 && !this.formValid()) {
      this.wizard.stop()
    }
  }

  displayWizardStep(step) {
    this.jQuery('input[type=submit]').prop('disabled', false)

    if (step === 1) {
      this.displayStep1()
    } else if (this.wizard.getStep() === 2) {
      this.displayStep2()
    } else {
      this.displayStep3()
    }
  }

  displayStep1() {
    this.jQuery('#btn-cancel').show()
  }

  displayStep2() {
    this.headerMessage('invite', this.getEmail())
    this.checkBoxDisable('invites', this.getEmail())
    this.jQuery('#btn-cancel').hide()
  }

  displayStep3() {
    const modulesText = `O convite de ${this.emailSpanElement(this.getEmail())} está quase pronto.`

    this.submitBtn.disabled = true
    this.jQuery('#invite-ready-text').html(modulesText)
    this.jQuery('#btn-cancel').hide()
  }

  getEmail() {
    return this.jQuery('#invites_form_email').val()
  }

  formValid() {
    return this.jQuery('.invites_form').valid()
  }

  validateForm() {
    this.jQuery('.invites_form').validate({
      errorClass: 'error-border',
      validClass: 'valid-border',
      rules: {
        'invites_form[email]': {
          required: true,
          email: true,
        },
      },
      messages: {
        'invites_form[email]': {
          required: 'Por favor preencha o email.',
          email: 'Preencha com um email válido.',
        },
      },
    })
  }
}

export default Invite
