// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
/* eslint-disable import/no-unresolved */

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import jQuery from 'jquery'
import 'jquery-validation'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.pt-BR'
import 'bootstrap-markdown/js/bootstrap-markdown'
import 'bootstrap-notify/bootstrap-notify'
import '../helpscout'
import '../firebase'
import Account from 'account'
import KTAppSettings from '../../../vendor/assets/javascript/metronic/ktap_settings'
import '../../../vendor/assets/javascript/metronic/scripts.bundle'

global.KTAppSettings = KTAppSettings
global.$ = jQuery
jQuery.fn.datepicker.defaults.language = 'pt-BR'
Rails.start()
Turbolinks.start()
ActiveStorage.start()
Account.start()
