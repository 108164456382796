class EntitySelection {
  constructor() {
    this.currentEntity = '.entity-selection .nav-item.active'
    this.toggleableEntities = '.entity-selection .nav-item:not(.active)'
    this.currentEntityIconUp = '.entity-selection .nav-item.active i.la-angle-up'
    this.currentEntityIconDown = '.entity-selection .nav-item.active i.la-angle-down'
    this.bindEvents()
    this.scrollCurrentEntityIntoView()
  }

  toggleElementOnMobile(element) {
    element.classList.toggle('d-none')
  }

  toggleIconOnMobile() {
    // Controls the display based on bootstrap display property classess
    // https://getbootstrap.com/docs/4.0/utilities/display/#hiding-elements
    // https://stackoverflow.com/questions/14207109/hiding-elements-in-responsive-layout
    const currentEntityIconUpElement = document.querySelector(this.currentEntityIconUp)
    const currentEntityIconDownElement = document.querySelector(this.currentEntityIconDown)

    currentEntityIconUpElement.classList.toggle('d-none')
    currentEntityIconUpElement.classList.toggle('d-block')

    currentEntityIconDownElement.classList.toggle('d-block')
    currentEntityIconDownElement.classList.toggle('d-none')
  }

  toogleOnMobile() {
    const toggleableEntityElements = document.querySelectorAll(this.toggleableEntities)
    toggleableEntityElements.forEach((element) => this.toggleElementOnMobile(element))

    this.toggleIconOnMobile()
  }

  bindEvents() {
    const currentEntityElement = document.querySelector(this.currentEntity)
    currentEntityElement.addEventListener('click', () => this.toogleOnMobile())
  }

  scrollCurrentEntityIntoView() {
    const currentEntityElement = document.querySelector(this.currentEntity)
    currentEntityElement.scrollIntoView({ block: 'nearest' })
  }
}

export default EntitySelection
