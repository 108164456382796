import IMask from 'imask'

class PhoneMask {
  constructor(inputs) {
    this.mobilePhoneMask = '(00) 90000-0000'
    this.fixedPhoneMask = '(00) 0000-0000'
    this.inputs = inputs
  }

  execute() {
    this.bindElements()
  }

  bindElements() {
    this.inputs.forEach((field) => {
      const input = document.querySelector(`[data-js=${field}]`)

      this.addInputMasks(input)
    })
  }

  addInputMasks(input) {
    IMask(input, { mask: [{ mask: this.fixedPhoneMask }, { mask: this.mobilePhoneMask }] })
  }
}

export default PhoneMask
