import PhoneMask from '../../components/phone_mask'

class SignUpForm {
  constructor() {
    this.inputs = ['user_phone']
    this.applyMasks()
  }

  applyMasks() {
    new PhoneMask(this.inputs).execute()
  }
}

export default SignUpForm
