function openBeacon(path) {
  window.Beacon('open')
  window.Beacon('navigate', path)
}
const setupHelpScout = () => {
  const centerHelp = document.getElementById('open_help_center')
  const faq = document.getElementById('open_faq')
  const callSupportElements = document.querySelectorAll('.open_call_support')
  const attendance = document.getElementById('open_attendance')

  if (centerHelp) centerHelp.addEventListener('click', () => openBeacon('/answers/'))

  if (faq) faq.addEventListener('click', () => openBeacon('/docs/search?query=Como integrar com PJ Bank'))

  if (callSupportElements) {
    callSupportElements.forEach((element) => {
      element.addEventListener('click', () => openBeacon('/ask/message'))
    })
  }

  if (attendance) attendance.addEventListener('click', () => openBeacon('/ask/message'))
}

document.addEventListener('turbolinks:load', setupHelpScout)
