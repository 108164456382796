import UserSignUpForm from './users/sign_up_form'
import UserSignInForm from './users/sign_in_form'
import UserResetPasswordSendEmailForm from './users/reset_password_send_email_form'
import UserResetPasswordForm from './users/reset_password_form'
import UserEditForm from './users/edit_form'
import SecurityForm from './users/security_form'
import AccountShow from './staff/account_show'
import FinancialIntegrationRuleShow from './staff/financial_integration_rule_show'
import UserAccountForm from './staff/user_account_form'
import EntitySelection from './dashboards/entity_selection'
import CollapseMenu from './account/collapse_menu'
import Invite from './account/invite'
import AddModules from './account/invite_to_modules'
import DestroyUser from './account/destroy_user'
import CollapsePermissions from './account/collapse_permissions'

export default {
  users: {
    UserSignUpForm,
    UserSignInForm,
    UserEditForm,
    UserResetPasswordSendEmailForm,
    UserResetPasswordForm,
    SecurityForm,
  },
  staff: { UserAccountForm, AccountShow, FinancialIntegrationRuleShow },
  dashboards: { EntitySelection },
  account: {
    CollapseMenu,
    Invite,
    AddModules,
    DestroyUser,
    CollapsePermissions,
  },
}
