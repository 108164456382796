import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import FlashMessage from '../../components/flash_message'
import ToggleSubmitButtonWithSpinner from '../../components/toggle_submit_button_with_spinner'

const errorMap = [
  { code: 'auth/user-not-found', message: 'Usuário ou senha inválidos.' },
  { code: 'auth/wrong-password', message: 'Usuário ou senha inválidos.' },
  {
    code: 'auth/too-many-requests',
    message: 'Conta bloqueada devido ao alto número de tentativas de acesso.'
      + 'Redefina sua senha ou tente novamente mais tarde.',
  },
  { code: 'auth/invalid-email', message: 'E-mail inválido.' },
]

class SignInForm {
  constructor() {
    this.jsForm = document.getElementById('login_js_form')
    this.emailInput = document.getElementById('email')
    this.passwordInput = document.getElementById('password')
    this.form = document.getElementById('new_users_login_form')
    this.idTokenInput = document.getElementById('users_login_form_id_token')
    this.submitButtonSelector = '#login-btn'
    this.flashMessage = new FlashMessage('.login-form')

    this.bindEvents()
  }

  bindEvents() {
    this.jsForm.addEventListener('submit', (event) => {
      event.preventDefault()
      this.sendSignInForm()
    })
  }

  sendSignInForm() {
    const auth = getAuth()
    const email = this.emailInput.value
    const password = this.passwordInput.value

    ToggleSubmitButtonWithSpinner.execute(this.submitButtonSelector)

    signInWithEmailAndPassword(auth, email, password)
      .then((response) => this.handleSignInResponse(response))
      .catch((error) => this.handleSignInError(error))
  }

  handleSignInResponse(response) {
    response.user.getIdToken()
      .then((idToken) => this.submitTokenToBackEnd(idToken))
  }

  handleSignInError(error) {
    this.flashMessage.execute(this.errorMessageFromCode(error.code), 'danger')
    this.passwordInput.value = ''
    ToggleSubmitButtonWithSpinner.execute(this.submitButtonSelector)
  }

  submitTokenToBackEnd(idToken) {
    this.idTokenInput.value = idToken
    this.passwordInput.value = ''
    this.form.submit()
  }

  errorMessageFromCode(errorCode) {
    const errorMessageMap = errorMap.filter((err) => err.code === errorCode)[0]

    if (errorMessageMap) {
      return errorMessageMap.message
    }

    return 'Ocorreu um erro ao autenticar, por favor tente novamente.'
  }
}

export default SignInForm
