import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth'
import _ from 'lodash'
import FlashMessage from '../../components/flash_message'
import ToggleSubmitButtonWithSpinner from '../../components/toggle_submit_button_with_spinner'

const errorMap = [
  { code: 'auth/invalid-action-code', message: 'Token inválido.' },
  { code: 'auth/expired-action-code', message: 'Token expirado.' },
]

const messagesMap = [
  {
    type: 'error_token_expired',
    message: 'O link que você usou para trocar a senha já expirou! \nPor favor, faça uma nova solicitação.',
  },
  { type: 'success', message: 'Você já pode acessar a Fintera com sua nova senha.' },
]

class ResetPasswordForm {
  constructor() {
    this.form = document.getElementById('kt_login_forgot_form')
    this.waitingAlertDiv = document.getElementById('verify-token')
    this.messagedDiv = document.getElementById('message')
    this.messagedText = document.getElementById('message-text')
    this.formDiv = document.getElementById('form_container')
    this.passwordInput = document.getElementById('forgot_password_password')
    this.passwordConfirmationInput = document.getElementById('forgot_password_password_confirmation')
    this.submitButton = '#reset-password-btn'
    this.flashMessage = new FlashMessage('.signin-signup-container')

    this.formErrors = []

    this.verifyCode()
  }

  verifyCode() {
    const auth = getAuth()

    verifyPasswordResetCode(auth, this.getActionCode())
      .then(() => { this.handleCodeSuccess() })
      .catch((error) => { this.handleResetPasswordError(error) })
  }

  handleCodeSuccess() {
    this.waitingAlertDiv.style.display = 'none'
    this.formDiv.style.display = 'block'

    this.bindEvents()
  }

  bindEvents() {
    this.form.onsubmit = (event) => {
      event.preventDefault()
      const passwordConfirmation = this.passwordConfirmationInput.value
      const password = this.passwordInput.value

      if (this.passwordValid(password, passwordConfirmation)) {
        this.sendResetPasswordForm()
      } else {
        this.handleInvalidResetPasswordForm()
      }
    }
  }

  sendResetPasswordForm() {
    const auth = getAuth()
    const password = this.passwordInput.value

    ToggleSubmitButtonWithSpinner.execute(this.submitButton)

    confirmPasswordReset(auth, this.getActionCode(), password)
      .then(() => this.handleResetPasswordSuccess())
      .catch((error) => this.handleResetPasswordError(error))
  }

  handleInvalidResetPasswordForm() {
    this.flashMessage.execute(this.formErrors.join('\r\n'), 'danger')

    this.clearPasswordInputs()
  }

  handleResetPasswordSuccess() {
    this.showMessageDiv('success')
    this.flashMessage.execute('Senha cadastrada com sucesso.', 'success')
    this.formDiv.style.display = 'none'
  }

  handleResetPasswordError(error) {
    this.showMessageDiv('error_token_expired')
    this.flashMessage.execute(this.errorMessageFromCode(error.code), 'warning')
    this.clearPasswordInputs()
    ToggleSubmitButtonWithSpinner.execute(this.submitButton)
  }

  getActionCode() {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)

    return searchParams.get('oobCode')
  }

  errorMessageFromCode(errorCode) {
    const errorMessageMap = errorMap.filter((err) => err.code === errorCode)[0]

    if (errorMessageMap) { return errorMessageMap.message }

    this.messagedDiv.style.display = 'none'

    return 'Ocorreu um erro ao redefinir a senha, por favor tente novamente.'
  }

  passwordValid(password, passwordConfirmation) {
    this.formErrors = []
    if (password !== passwordConfirmation) {
      this.formErrors.push('As senhas não coincidem.')
    }

    if (password.length < 8) {
      this.formErrors.push('A senha precisa de pelo menos 8 caracteres.')
    }

    return _.isEmpty(this.formErrors)
  }

  showMessageDiv(message) {
    const messageFromMap = messagesMap.filter((err) => err.type === message)[0]

    this.waitingAlertDiv.style.display = 'none'

    this.messagedText.textContent = messageFromMap.message

    this.messagedDiv.style.display = 'block'
  }

  clearPasswordInputs() {
    this.passwordInput.value = ''
    this.passwordConfirmationInput.value = ''
  }
}

export default ResetPasswordForm
