import pages from './pages'
import enableClickableRow from './components/enable_clickable_row'

class Account {
  start() {
    window.finteraAccount = this.initializeFinteraAccount()
  }

  initializeFinteraAccount() {
    return {
      functions: { enableClickableRow },
      pages,
    }
  }
}

export default new Account()
