import PhoneMask from '../../components/phone_mask'

class UserAccountForm {
  constructor() {
    this.inputs_phone_mask = ['phone-mask']
    this.applyMasks()
  }

  applyMasks() {
    new PhoneMask(this.inputs_phone_mask).execute()
  }
}

export default UserAccountForm
