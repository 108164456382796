class FlashMessage {
  constructor(containerClass) {
    this.container = document.querySelector(containerClass)
    this.alertElement = document.querySelector('.alert')
    this.alertTemplateElement = document.getElementById('alert-template')
  }

  execute(message, type) {
    const messageClass = `alert-light-${type}`

    this.displayMessage(message, messageClass)
  }

  displayMessage(message, messageClass) {
    this.reloadAlertElement()

    if (this.alertElement) { this.removeAlertElement() }

    this.addAlertElement(messageClass)

    this.alertElement.querySelector('.alert-text').textContent = message
  }

  removeAlertElement() {
    this.alertElement.parentNode.removeChild(this.alertElement)

    this.reloadAlertElement()
  }

  addAlertElement(messageClass) {
    this.alertElementClone = document.importNode(this.alertTemplateElement.content, true)

    this.container.prepend(this.alertElementClone)

    this.reloadAlertElement()

    this.alertElement.classList.add(messageClass)
  }

  reloadAlertElement() {
    this.alertElement = document.querySelector('.alert')
  }
}

export default FlashMessage
