class ModuleEntitiesStep {
  checkBoxDisable(modalId, userEmail) {
    const self = this

    this.cleanCheckBox(modalId)

    this.jQuery(`*[data-user-module-entity^="${userEmail}_"]`).each(function disable() {
      let data = this.getAttribute('data-user-module-entity')
      data = JSON.parse(data.substring(data.lastIndexOf('[')))

      data.forEach((e) => {
        self.jQuery(`#${modalId}_form_module_entities_${e}`).prop('checked', true)
        self.jQuery(`#${modalId}_form_module_entities_${e}`).prop('disabled', true)
      })
    })

    if (this.check_adm && this.check_adm.checked) {
      this.checkModules(this.check_adm.checked)
    }
  }

  headerMessage(modalId, userEmail) {
    const modulesText = `Quais módulos das suas empresas ${this.emailSpanElement(userEmail)} poderá acessar?`
    this.jQuery(`#${modalId} .modules-text`).html(modulesText)
  }

  emailSpanElement(userEmail) {
    return `<span style="color: #6193ee">${userEmail}</span>`
  }

  cleanCheckBox(modalId) {
    this.jQuery(`[id^="${modalId}_form_module_entities_"]`).each(function enable() {
      this.checked = false
      this.disabled = false
    })
  }

  checkModules(boolean) {
    document.querySelectorAll("*[id^='invites_form_module_entities_']").forEach((element) => {
      const el = element

      el.checked = boolean
      el.disabled = boolean
    })
  }
}

export default ModuleEntitiesStep
