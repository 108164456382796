class CollapseMenu {
  constructor(tab) {
    this.tabItems = [
      { name: 'entity', btn: 'btn-entity' },
      { name: 'invoice', btn: 'btn-invoice' },
      { name: 'user', btn: 'btn-user' },
      { name: 'general-data', btn: 'btn-general-data' },
      { name: 'integration-data', btn: 'btn-integration-data' },
    ]
    this.jQuery = window.$

    const tabItem = this.tabItems.find((item) => item.name === tab)

    this.activeTab(tabItem)
    this.bindEventsToTabItems()
  }

  activeTab(tabItem) {
    this.allAddHide()
    this.allRemoveClass()
    this.setCurrentTabUrlParam(tabItem.name)

    this.jQuery(`#${tabItem.name}`).toggleClass('hide')
    this.jQuery(`#${tabItem.btn}`).addClass('active')
  }

  bindEventsToTabItems() {
    this.tabItems.forEach((item) => this.bindTabEvents(item))
  }

  bindTabEvents(tabItem) {
    const tabListener = document.querySelector(`#${tabItem.btn}`)

    if (tabListener === null) { return }

    tabListener.addEventListener('click', () => this.activeTab(tabItem))
  }

  allAddHide() {
    this.tabItems.forEach((item) => this.addHideClass(item.name))
  }

  allRemoveClass() {
    this.tabItems.forEach((item) => this.removeActiveClass(item.btn))
  }

  addHideClass(elementId) {
    this.jQuery(`#${elementId}`).addClass('hide')
  }

  removeActiveClass(elementId) {
    this.jQuery(`#${elementId}`).removeClass('active')
  }

  setCurrentTabUrlParam(tabName) {
    const url = new URL(window.location)

    url.searchParams.set('current_tab', tabName)

    window.history.pushState(null, '', url)
  }
}

export default CollapseMenu
