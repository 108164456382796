class DestroyUser {
  constructor() {
    this.jQuery = window.$
    this.bindEvents()
  }

  bindEvents() {
    this.destroyUserEvent()
    this.destroyUserModuleEntitiyEvent()
  }

  destroyUserEvent() {
    const self = this

    self.jQuery('.destroy_user_btn').click(function destroyUserForm() {
      const accountId = this.getAttribute('data-account-id')
      const userUuid = this.getAttribute('data-user-uuid')
      const userEmail = this.getAttribute('data-user-email')

      self.setModelBody(userEmail, 'da conta?')
      self.setHref(accountId, `users/${userUuid}`)
    })
  }

  destroyUserModuleEntitiyEvent() {
    const self = this

    self.jQuery('.destroy_user_module_entity_btn').click(function destroyUserModuleEntitiyForm() {
      const accountId = this.getAttribute('data-account-id')
      const userEmail = this.getAttribute('data-user-email')
      const userModuleEntityId = this.getAttribute('data-user-module-entity-id')
      const moduleName = this.getAttribute('data-module-name')
      const entityName = this.getAttribute('data-entity-name')

      self.setModelBody(userEmail, `do Módulo ${moduleName} da empresa ${entityName}?`)
      self.setHref(accountId, `user_module_entities/${userModuleEntityId}`)
    })
  }

  setModelBody(userEmail, message) {
    this.jQuery('#destroy_user .modal-body p').html(`Deseja remover o usuário ${userEmail} ${message}`)
  }

  setHref(accountId, href) {
    this.jQuery('#destroy_user .modal-footer a').attr('href', `/accounts/${accountId}/${href}`)
  }
}

export default DestroyUser
