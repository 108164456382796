class FinancialIntegrationRuleShow {
  constructor() {
    this.togglesActivateModule = document.querySelectorAll('.submit_change_status')
    this.jQuery = window.$
    this.bindEvents()
  }

  bindEvents() {
    this.togglesActivateModule.forEach((toggle) => {
      toggle.addEventListener('change', () => {
        toggle.form.submit()
      })
    })
  }
}

export default FinancialIntegrationRuleShow
